import { colors, createTheme } from '@mui/material';
import { grey, red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
    typography: {
        fontFamily: ['Bellefair', 'serif'].join(','),
        allVariants: {
            color: grey[400],
            fontSize: '1.2rem'
        }
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    color: grey[50]
                }
            }
        },
        MuiLink: {
            defaultProps: {
                target: '_blank'
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    color: 'black',
                    '&::placeholder': {
                        color: 'black'
                    },
                    '& .MuiInputBase-input': {
                        background: grey[50],
                        borderRadius: '5px'
                    }
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: grey[50]
                }
            }
        }
    },
    palette: {
        primary: {
            main: grey[50]
        },
        secondary: {
            main: grey[400]
        },
        divider: '#D4BF7C',
        error: {
            main: red.A400
        },
        background: {
            default: '#000000'
        }
    }
});

export default theme;
