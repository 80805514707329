import { Grid2, Typography } from '@mui/material';

export function Itinerary() {
    return (
        <Grid2 container alignItems="center" justifyContent="center" margin="16px 12px" padding={2}>
            <Grid2 container size={{ xs: 12, sm: 8 }} spacing={2} marginTop={3}>
                <Typography variant="body1">
                    We'll be celebrating both Saturday and Sunday and look forward to quality time with all of
                    you. The itinerary for both are below.
                    <br></br>
                    <br></br>
                    <hr className="yellow-hr"></hr>
                    <i>
                        Note: Calvin and Becky will be rafting with the 7:30am time slot on Saturday. And will be
                        socializing around the canteen/volleyball courts afterwards.
                    </i>
                    <br></br>
                    <hr className="yellow-hr"></hr>
                    <br></br>
                </Typography>
                <Typography variant="body1">
                    <p className="info-heading yellow">Saturday (August 30th) Activities and hanging out.</p>
                    <ul>
                        <li>7:30am - noonish Wilderness Tours guided rafting trip (w/ Calvin and Becky)</li>
                        <li>Noon - socializing</li>
                        <li>Noon - 5pm-ish, the second time slot of Wilderness Tours guided rafting trip</li>
                        <li>7pm - dinner at the canteen</li>
                        <li>8pm - karaoke on the canteen deck</li>
                        <li>All day events - volleyball, beach, socializing</li>
                    </ul>
                    <p className="info-heading yellow">Sunday (August 31st) Wedding day!</p>
                    <ul>
                        <li>3:30 pm - Shuttle from Wilderness Tours campground to River Barn</li>
                        <li>3:45 pm - Arrival to River Barn</li>
                        <li>4:00 pm - Ceremony</li>
                        <li>6:00 pm - Dinner</li>
                        <li>8:00 pm - Dancing</li>
                        <li>10:00 pm - Early shuttle back to Campground</li>
                        <li>12:00 pm - Late shuttle back to Campground.</li>
                    </ul>
                </Typography>
            </Grid2>
        </Grid2>
    );
}
