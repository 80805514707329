import { TopbarTabs } from './TopbarTabs';
import { Grid2 } from '@mui/material';
import header from './goldhintbanner.jpg';
import { useSearchParams } from 'react-router-dom';

function App() {
    const [searchParmas] = useSearchParams();
    return (
        <Grid2>
            <img style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }} src={header} alt="Save the Date" />
            <div className="bgcwcontainer">
                <div className="bgcw">
                    BG
                    <div style={{ margin: '5px' }}>&</div>
                    CW
                </div>
            </div>

            <Grid2 container justifyContent="center">
                <TopbarTabs />
            </Grid2>
        </Grid2>
    );
}

export default App;
