import { Grid2, Link, Typography } from '@mui/material';
import Iconify from '../Iconify';
import glampingImage from '../GlampingTents.webp';

export function AccommodationsPage() {
    return (
        <Grid2 container alignItems="center" justifyContent="center" margin="16px 12px" padding={2}>
            <Grid2 container size={{ xs: 12, sm: 8 }} spacing={2} marginTop={3}>
                <Typography variant="body1">
                    We invite you all to stay with us for the entire long weekend at{' '}
                    <Link href="https://maps.app.goo.gl/owbJAkVFsgiTvoCG8">
                        Wilderness Tours White Water Resort
                        <Iconify icon="mdi:external-link" />
                    </Link>
                    . Calvin and Becky will be frolicking throughout the grounds from Fri Aug 29th to Mon Sept 1st.
                </Typography>
                <Typography variant="body1">
                    To stay at the resort, there is a 2 night minimum or a +50% surcharge for a 1 night stay. To view
                    the cabin options and book via phone or online, please visit:{' '}
                    <Link href="https://wildernesstours.com/rebecca-gowland-calvin-wiebe-wedding/">
                        Our Wilderness Tours Booking Site
                        <Iconify icon="mdi:external-link" />
                    </Link>
                    . The password is: <b>Gowland&Wiebe2025</b>
                </Typography>
                <img style={{ maxWidth: '100%' }} src={glampingImage} alt="Glamping" />
                <Typography variant="body1">
                    Please note the rustic nature and shared facilities of the resort, so you can plan your comforts
                    accordingly.
                </Typography>
                <Typography className="info-heading yellow">Staying offsite</Typography>
                <Typography variant="body1" marginBottom={8}>
                    If you end up deciding to stay at a nearby hotel and plan on drinking, please let us know, so we can
                    arrange the right number of cabs.
                </Typography>
            </Grid2>
        </Grid2>
    );
}
