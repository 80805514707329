import { Grid2 } from '@mui/material';
import logo from '../SaveTheDate.jpg';

export function HomePage() {
    return (
        <Grid2 container alignItems="center" justifyContent="center">
            <Grid2 size={{ xs: 12, sm: 8 }}>
                <img style={{ maxWidth: '100%' }} src={logo} alt="Save the Date" />
            </Grid2>
        </Grid2>
    );
}
