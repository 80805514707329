import {
    Grid2,
    Typography,
    TextField,
    Button,
    Dialog,
    Stack,
    Select,
    MenuItem,
    CircularProgress,
    type SelectChangeEvent,
    Alert,
    type Theme,
    styled,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import InvitationBackground from '../InvitationBackground.jpg';
import InvitationBackgroundSmall from '../InvitationBackgroundSmall.jpg';

const InviteContainer = styled('div')(({ theme }: { theme: Theme }) => ({
    minWidth: '100%',
    minHeight: '886px',
    paddingTop: '50px',
    backgroundImage: `url(${InvitationBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    [theme.breakpoints.up('md')]: {
        minWidth: '670px'
    },
    [theme.breakpoints.down('sm')]: {
        backgroundImage: `url(${InvitationBackgroundSmall})`
    }
}));

export function InvitationPage() {
    const [params] = useSearchParams();
    const email = params.get('email') || '';
    const [names, setNames] = useState('');
    const [isBadEmailError, setIsBadEmailError] = useState(false);
    const [songRequest, setSongRequest] = useState('');
    const [guests, setGuests] = useState(1);
    const [maxGuests, setMaxGuests] = useState(1);
    const [dietaryRestrictions, setDietaryRestrictions] = useState('');
    const [submitMessage, setSubmitMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [alreadySubmitted, setAlreadySubmitted] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const fetchNames = useCallback(async (email: string) => {
        try {
            const res = await fetch('https://get-invitee-info-646286843763.us-central1.run.app', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email
                })
            });
            const body = await res.json();
            if (body.error === 'email not found') {
                setSubmitMessage(
                    'Whoops. Email not found. Please use the email of the Save The Date receiver. Thanks.'
                );
                setIsDialogOpen(true);
                setIsBadEmailError(true);
                setNames('');
                return;
            }
            const maxGuests = body.maxGuests;
            const alredySubmittedGuests = body.guests !== undefined ? body.guests : maxGuests;
            setNames(body.names);
            setGuests(alredySubmittedGuests);
            setDietaryRestrictions(body.dietaryRestrictions || '');
            setMaxGuests(maxGuests);
            setSongRequest(body.songs || '');
            if (body.guests) {
                setAlreadySubmitted(true);
            }
        } catch (error) {
            console.log('----ERROR----', error);
        }
    }, []);

    useEffect(() => {
        if (email) {
            setSubmitMessage('');
            setIsDialogOpen(false);
            setIsBadEmailError(false);
            fetchNames(email);
        } else {
            setSubmitMessage('Whoops. Email not found. Please use the email of the Save The Date receiver. Thanks.');
            setIsDialogOpen(true);
            setIsBadEmailError(true);
            setNames('');
        }
    }, [email]);

    const onSubmit = async (declined = false) => {
        if (!email) {
            setSubmitMessage('Please fill in your email.');
            setIsDialogOpen(true);
            return;
        }

        setIsSubmitting(true);

        const res = await fetch('https://accept-invitation-646286843763.us-central1.run.app', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                numOfGuests: declined ? 0 : guests,
                songRequest: declined ? '' : songRequest,
                dietaryRestrictions: declined ? '' : dietaryRestrictions
            })
        });

        setIsSubmitting(false);

        if (!res.ok) {
            const body = await res.json();
            if (body.error === 'email not found') {
                setSubmitMessage(
                    'Whoops. Email not found. Please use the email of the Save The Date receiver. Thanks.'
                );
            }
        } else {
            setSubmitMessage('Submitted successfully! Thanks for letting us know.');
        }
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    let dialog = null;
    if (submitMessage) {
        dialog = (
            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <p style={{ padding: '10px' }}>{submitMessage}</p>
            </Dialog>
        );
    }

    function handleGuestsChange(event: SelectChangeEvent<number>) {
        const guests = Number(event.target.value);
        if (guests <= maxGuests && guests >= 0) {
            setGuests(guests);
        }
    }

    const declined = guests === 0;

    const guestOptions = [];
    for (let i = 0; i < maxGuests + 1; i++) {
        guestOptions.push(
            <MenuItem sx={{ color: 'black' }} key={i} value={i}>
                {i}
            </MenuItem>
        );
    }

    return (
        <Stack alignItems="center" justifyContent="center">
            {alreadySubmitted && (
                <Stack sx={{ width: '100%' }} alignItems="center">
                    <Alert sx={{ maxWidth: '300px' }} severity="success">
                        Thank you for already responding
                    </Alert>
                </Stack>
            )}
            <InviteContainer>
                {!names && !isBadEmailError && (
                    <Stack width="100%" height="30rem" alignItems={'center'} justifyContent={'center'}>
                        <CircularProgress />
                    </Stack>
                )}
                {!names && isBadEmailError && (
                    <Stack width="100%" height="30rem" alignItems={'center'} justifyContent={'center'}>
                        <Grid2
                            container
                            spacing={2}
                            direction="column"
                            paddingTop={6}
                            paddingBottom={10}
                            paddingLeft={isSmallScreen ? '35px' : '0px'}
                            paddingRight={isSmallScreen ? '60px' : '0px'}
                            alignItems="center"
                        >
                            {dialog}
                            <Typography
                                sx={{
                                    fontSize: 'xx-large',
                                    padding: '0.7rem 1.3rem',
                                    letterSpacing: '8px'
                                }}
                                variant="body1"
                                textAlign="center"
                                width={isSmallScreen ? '100%' : '400px'}
                            >
                                Could not find invitee
                            </Typography>
                        </Grid2>
                    </Stack>
                )}
                {names && (
                    <Grid2
                        container
                        spacing={2}
                        direction="column"
                        paddingTop={6}
                        paddingBottom={10}
                        paddingLeft={isSmallScreen ? '35px' : '0px'}
                        paddingRight={isSmallScreen ? '60px' : '0px'}
                        alignItems="center"
                    >
                        {dialog}
                        <Typography
                            sx={{
                                fontSize: 'xx-large',
                                padding: '0.7rem 1.3rem',
                                letterSpacing: '8px'
                            }}
                            variant="body1"
                            textAlign="center"
                            width={isSmallScreen ? '100%' : '400px'}
                        >
                            TO: {names.toUpperCase()}
                        </Typography>
                        <Stack
                            sx={{
                                borderRadius: '10px',
                                padding: '1rem'
                            }}
                        >
                            <Typography
                                variant="body1"
                                textAlign="center"
                                width={isSmallScreen ? '100%' : '300px'}
                                sx={{ fontSize: 'medium' }}
                            >
                                CALVIN AND BECKY ARE PLEASED TO INVITE YOU TO OUR WEDDING ON
                                <br /> <br />{' '}
                                <span style={{ fontSize: '1.6rem', color: '#eae7e7', letterSpacing: '8px' }}>
                                    AUGUST 31ST 2025
                                </span>
                                <br />
                                <p style={{ fontFamily: 'baletta' }}>at</p>
                                <span style={{ fontSize: '1.6rem', color: '#eae7e7' }}>
                                    THE RIVER BARN WILDERNESS TOURS
                                </span>
                                <p style={{ fontFamily: 'baletta' }}>at</p>
                                <span style={{ fontSize: '1.6rem', color: '#eae7e7' }}>4PM</span>
                            </Typography>
                        </Stack>
                        <Stack
                            direction="column"
                            alignItems={'center'}
                            justifyContent={'center'}
                            spacing={1}
                            width={'100%'}
                            sx={{
                                padding: '0.7rem'
                            }}
                        >
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent={'center'}>
                                <Typography>Number of guests attending dinner</Typography>
                                <Select
                                    placeholder="Guests attending"
                                    value={guests}
                                    onChange={handleGuestsChange}
                                    name="guestsCount"
                                    size="small"
                                    variant="outlined"
                                    sx={{ width: '80px', backgroundColor: 'white' }}
                                >
                                    {guestOptions}
                                </Select>
                            </Stack>
                            {!declined && (
                                <Stack width={'70%'} spacing={1} alignItems="center" justifyContent={'center'}>
                                    <Typography>Dietary Restrictions</Typography>
                                    <TextField
                                        placeholder="Any dietary needs we should be aware of?"
                                        value={dietaryRestrictions}
                                        name="dietaryRestrictions"
                                        onChange={(e) => setDietaryRestrictions(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Stack>
                            )}
                            {!declined && (
                                <Stack width={'70%'} spacing={1} alignItems="center" justifyContent={'center'}>
                                    <Typography>Song requests</Typography>
                                    <TextField
                                        placeholder="Song requests"
                                        value={songRequest}
                                        name="songRequest"
                                        onChange={(e) => setSongRequest(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Stack>
                            )}
                        </Stack>

                        <Stack spacing={1} direction={'row'} alignItems="center" justifyContent={'center'}>
                            <Button
                                color="error"
                                sx={{ backgroundColor: '#303233' }}
                                variant="contained"
                                onClick={() => onSubmit(true)}
                            >
                                {isSubmitting ? 'Submitting...' : 'Decline'}
                            </Button>
                            <Button
                                sx={{
                                    color: '#303233'
                                }}
                                color="primary"
                                variant="contained"
                                onClick={() => onSubmit()}
                            >
                                {isSubmitting ? 'Submitting...' : 'Accept'}
                            </Button>
                        </Stack>
                    </Grid2>
                )}
            </InviteContainer>
        </Stack>
    );
}
