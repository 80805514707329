import { Grid2, Typography, Link } from '@mui/material';
import mapImg from '../directions.jpg';

export function FAQ() {
    return (
        <Grid2 container alignItems="center" justifyContent="center" margin="16px 12px" padding={2}>
            <Grid2 container size={{ xs: 12, sm: 8 }} spacing={4} marginTop={3}>
                <Typography variant="body1">
                    Below are miscellaneous questions and answers that you may find helpful.
                </Typography>
                <Typography variant="body1">
                    <p className="question">
                        <p>Will I have fun?</p>
                        <p>Yes.</p>
                    </p>
                    <p className="question">
                        <p>What is the dress code?</p>
                        <p>
                            Loosely semi formal. The wedding party will be in black, and we'd love if you could bring
                            some colour to our life.
                        </p>
                        <p>
                            Dress weather appropriate. Don't wear a suit jacket or long-sleeve dress shirt if it will
                            cause dehydration, the open bar will do that enough for you.
                        </p>
                        <p>
                            For shoes, please note there is a bit of a walk to the ceremony spot down a gravel road, and
                            the dinner will be on outdoors on grass.
                        </p>
                    </p>
                    <p className="question">
                        <p>Directions</p>
                        <p>
                            The ceremony and reception will be down the road from the accommodations at the{' '}
                            <Link href="https://maps.app.goo.gl/Mu8uxz37D23UhCU86">Ottawa River Barn.</Link>
                        </p>
                        <img style={{ width: '100%' }} src={mapImg} alt="Save the Date" />
                    </p>
                    <p className="question">
                        <p>Where am I sleeping?</p>
                        <p>
                            Please see the <Link href="/accommodations">accommodations page</Link> for more details
                        </p>
                    </p>
                    <p className="question">
                        <p>How do I book rafting?</p>
                        <p>
                            You can call Wilderness Tours at 1-888-723-8669 or{' '}
                            <Link href="https://wildernesstours.com/my-trip/dates-and-rates/?cat=A&code=MCA&opt=1&cocode=1&rate=1">
                                Book Online
                            </Link>{' '}
                            selecting August 30th, 2025.
                        </p>
                    </p>
                    <p className="question">
                        <p>I did not find the answer I was looking for.</p>
                        <p>
                            Don't hesitate to email us at either calvin.wiebe@gmail.com, beckygowland@gmail.com, or
                            message one of us on Facebook Messenger. We will get you sorted.
                        </p>
                    </p>
                </Typography>
            </Grid2>
        </Grid2>
    );
}
