import { Grid2, styled, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { AccommodationsPage } from './pages/AccommodationsPage';
import { Itinerary } from './pages/Itinerary';
import { FAQ } from './pages/FAQ';
import { PhotosPage } from './pages/PhotosPage';
import { InvitationPage } from './pages/InvitationPage';

const StyledTab = styled(Link)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        fontSize: '0.8rem'
    }
}));

export function TopbarTabs() {
    const location = useLocation();
    const selectedTab = location.pathname;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid2 container justifyContent="center" flexDirection="column" size={{ xs: 12, sm: 8 }}>
            <Grid2 container alignItems="center" justifyContent="center">
                <Tabs
                    variant="scrollable"
                    value={selectedTab}
                    TabIndicatorProps={{
                        style: {
                            background: theme.palette.divider
                        }
                    }}
                    orientation={isSmallScreen ? 'vertical' : 'horizontal'}
                >
                    <Tab label="Home" value={'/'} component={StyledTab} to={'/'} />
                    <Tab label="Accommodations" value={'/accommodations'} component={StyledTab} to="/accommodations" />
                    <Tab label="Itinerary" value={'/itinerary'} component={StyledTab} to="/itinerary" />
                    <Tab label="FAQ" value={'/faq'} component={StyledTab} to="/faq" />
                    <Tab label="Photo Dump" value="/photos" component={StyledTab} to="/photos" />
                </Tabs>
            </Grid2>

            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/accommodations" element={<AccommodationsPage />} />
                <Route path="/itinerary" element={<Itinerary />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/photos" element={<PhotosPage />} />
                <Route path="/invitation" element={<InvitationPage />} />
            </Routes>
        </Grid2>
    );
}
