import { Alert, Button, CircularProgress, Grid2, TextField, Typography } from '@mui/material';

import { useRef, useState } from 'react';
import Iconify from '../Iconify';

export function PhotosPage() {
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [showSubmitted, setShowSubmitted] = useState(-1);
    const [showError, setShowError] = useState('');
    const [user, setUser] = useState('');
    const [files, setFiles] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            const filesAsArray = Array.from(event.target.files);
            setFiles([...filesAsArray, ...files]);
        }
    }

    const uploadPhotos = async (event: any) => {
        event.preventDefault();
        const formData = new FormData();
        const formattedUser = user.replace(/\s/g, '').toLowerCase();
        if (!formattedUser) {
            setShowError('Please enter enter your name.');
            return;
        }
        formData.append('user', formattedUser);
        if (files) {
            for (let i = 0; i < files.length; i++) {
                formData.append('images', files[i]);
            }
        }
        try {
            setLoading(true);
            const res = await fetch('https://photo-dump-646286843763.us-central1.run.app', {
                method: 'POST',
                body: formData
            });
            if (res.status === 200) {
                setShowError('');
                setShowSubmitted(files.length);
                setFiles([]);
                setTimeout(() => {
                    setShowSubmitted(-1);
                }, 5000);
            } else {
                setShowError('Error submitting photos. Please try again. ' + res.body);
            }
        } catch (error) {
            setShowError('Error submitting photos. Please try again. ' + error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid2 container alignItems="center" justifyContent="center" margin="16px 12px" padding={2}>
                <Grid2 container size={{ xs: 12, sm: 8 }} spacing={1} marginTop={3} direction='column' component={'form'}>
                    <Typography variant="body1">
                        We would love to see your photos from the weekend! Please upload them here and we'll save them
                        to the public google drive. Please note that anyone can view and download photos from this
                        folder.
                    </Typography>
                    <Grid2 container spacing={2} direction="row">
                        <Button
                            variant="contained"
                            onClick={() => inputFileRef.current?.click()}
                            endIcon={<Iconify icon="mdi:camera" width={20} />}
                        >
                            Select
                        </Button>
                        <Button
                            variant="outlined"
                            target="_blank"
                            href="https://drive.google.com/drive/folders/1UzjcENw7pfj97j6wSjyKzBcSJfKHEm-K?usp=drive_link"
                            endIcon={<Iconify icon="logos:google-drive" width={20} />}
                        >
                            View
                        </Button>
                    </Grid2>
                    <div>
                        <Typography variant="body1">Let us know who to credit these photos too</Typography>
                        <TextField
                            fullWidth
                            placeholder="Name"
                            value={user}
                            name="user"
                            id="user"
                            type="user"
                            autoComplete="user"
                            required
                            onChange={(e) => setUser(e.target.value)}
                            variant="outlined"
                            size='small'
                        />
                    </div>
                    {showSubmitted !== -1 && (
                        <Alert variant="filled" severity="success">
                            Successfully submitted {showSubmitted} photo
                            {showSubmitted > 1 ? 's' : ''}!
                        </Alert>
                    )}
                    {showError && (
                        <Alert variant="filled" severity="error">
                            {showError}
                        </Alert>
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        ref={inputFileRef}
                        name="file"
                        style={{ display: 'none' }}
                        onChange={handleChange}
                    />

                    {files.length && (
                        <Button
                            disabled={loading}
                            variant="contained"
                            type="submit"
                            onClick={uploadPhotos}
                            startIcon={loading ? <CircularProgress /> : undefined}
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </Button>
                    )}
                    {files && (
                        <Grid2 container spacing={2} direction="row">
                            {Array.from(files).map((localFile, i) => (
                                <div
                                    key={i + localFile.name}
                                    style={{
                                        maxHeight: '200px',
                                        border: '1px solid white',
                                        margin: '10px',
                                        width: 'fit-content'
                                    }}
                                >
                                    <img
                                        style={{
                                            maxHeight: '200px'
                                        }}
                                        src={URL.createObjectURL(localFile)}
                                    />
                                    <Button
                                        style={{
                                            padding: 0,
                                            width: '20px',
                                            position: 'absolute',
                                            marginTop: '-10px',
                                            marginLeft: '-25px'
                                        }}
                                        onClick={() => {
                                            setFiles(files.filter((_file, index) => i !== index));
                                        }}
                                        startIcon={<Iconify icon="mdi:cross-circle" width={20} />}
                                    ></Button>
                                </div>
                            ))}
                        </Grid2>
                    )}
                </Grid2>
        </Grid2>
    );
}
